import { useQuery, UseQueryResult } from "@tanstack/react-query"
import { AxiosResponse } from "axios"

import { useMedusa } from "medusa-react"
import {
  PricedProduct,
  PricedVariant,
} from "@medusajs/medusa/dist/types/pricing"
import { useStore } from "../../context/NewStoreContext"

export type OOSProduct = PricedProduct & {
  variant: PricedVariant
  quantity_before: number
}

interface CheckInventoryResponse {
  has_oos: boolean
  oos_items: OOSProduct[]
}

interface SessionStorageInventoryData {
  has_oos: boolean
  oos_items: OOSProduct[]
  step: "cart" | "checkout"
}

export const useCheckInventory = (
  step?: "cart" | "checkout",
  correct_inventory = true
): UseQueryResult<
  {
    has_oos: boolean
    oos_items: OOSProduct[]
    step: "cart" | "checkout"
  },
  unknown
> => {
  const { client: medusa } = useMedusa()
  const { cart, updateCart } = useStore()
  const cartId = cart?.id
  const cartItems = cart?.items

  const cartItemsData = cartItems?.map((i) => {
    return {
      id: i.id,
      quantity: i.quantity,
      inventory_quantity: i.variant.inventory_quantity,
    }
  })

  return useQuery(
    ["check-inventory", cartId, cartItemsData],
    async () => {
      let currentOOS: SessionStorageInventoryData

      try {
        currentOOS = JSON.parse(sessionStorage.getItem("tekla::cart:oss"))
      } catch (error) {
        return
      }

      // If no items in cart and no items in oos, return
      if (!cartItems.length && !currentOOS?.oos_items?.length) {
        return {
          has_oos: false,
          oos_items: [],
          step: step,
        }
      }

      let oosSessionData = {
        has_oos: false,
        oos_items: [],
        step: step,
      }

      // Get items in cart that are in stock
      const cartItemsInStock = cartItems
        .filter((i) => i.variant.inventory_quantity > 0)
        ?.map((i) => i.variant.id)

      // Remove items from oos that are now in stock
      oosSessionData.oos_items =
        currentOOS?.oos_items?.length > 0
          ? currentOOS?.oos_items?.filter((i) => {
              if (
                cartItemsInStock?.length > 0 &&
                cartItemsInStock.includes(i.variant.id) &&
                i.variant.inventory_quantity < 1
              ) {
                return false
              }
              return true
            })
          : []

      if (cartItems.some((i) => i.quantity > i.variant.inventory_quantity)) {
        const { data }: AxiosResponse<CheckInventoryResponse> =
          await medusa.client.request(
            "GET",
            `store/carts/${cartId}/check-inventory?correct_inventory=${correct_inventory}`
          )

        oosSessionData.has_oos = data.has_oos
        oosSessionData.oos_items = [
          // Remove items that are part of fetched data from current session storage
          ...oosSessionData?.oos_items?.filter(
            (i) =>
              !data.oos_items.map((i) => i.variant.id).includes(i.variant.id)
          ),
          ...data.oos_items,
        ]
      } else {
        oosSessionData.has_oos = false
        oosSessionData.oos_items = oosSessionData.oos_items || []
      }

      return {
        ...oosSessionData,
      }
    },
    {
      enabled: Boolean(cartId),
      keepPreviousData: true,
      onSuccess: (data) => {
        sessionStorage.setItem("tekla::cart:oss", JSON.stringify(data))

        if (data?.has_oos) {
          updateCart.mutateAsync({})
        }
      },
      onError: (error) => {
        console.log("error", error)
      },
    }
  )
}
