import { EnrichedMedusaProduct } from "gatsby-node"
import { ProductCategory } from "@medusajs/medusa"
import { capitalize } from "lodash"

export const getDownCollectionTitle = (productSku: string) => {
  if (productSku.startsWith("DN-WS")) {
    return "Winter duvet"
  }

  if (productSku.startsWith("DN-AS")) {
    return "Autumn duvet"
  }

  if (productSku.startsWith("DN-SS")) {
    return "Summer duvet"
  }

  if (productSku.startsWith("DN-SG")) {
    return "Spring duvet"
  }

  if (productSku.startsWith("DN-HIGH")) {
    return "High pillow"
  }

  if (productSku.startsWith("DN-MEDIUM")) {
    return "Medium pillow"
  }

  if (productSku.startsWith("DN-LOW")) {
    return "Low pillow"
  }

  if (productSku.startsWith("TD-SD")) {
    return "Spring / Summer duvet"
  }

  if (productSku.startsWith("TD-WD")) {
    return "Autumn / Winter duvet"
  }

  if (productSku.startsWith("TD-HP-")) {
    return "Down pillow"
  }

  return "Down collection"
}

export const getTitle = (
  product: Pick<
    EnrichedMedusaProduct,
    "sku" | "color" | "title" | "isGiftcard" | "type" | "metadata"
  >,
  collection?: Pick<ProductCategory, "id" | "handle" | "name" | "metadata">
) => {
  if (!product) {
    return
  }

  if (product?.isGiftcard || product.type?.value === "Gift set") {
    return product.title
  }

  if (product.sku?.startsWith("TD-") || product.sku?.startsWith("DN-")) {
    return getDownCollectionTitle(product.sku)
  }

  if (collection?.metadata?.fabric && product.type?.value) {
    // Exception for Nagoya and Uji
    const typeValue = product.type.value.includes("shoes")
      ? capitalize(product.type.value)
      : product.type.value.toLowerCase()
    return collection.metadata.fabric + " " + typeValue
  }

  if (product.type?.value) {
    // Exceptions
    if (
      collection?.handle === "blankets" ||
      collection?.handle === "the-colours-of-le-corbusier"
    ) {
      return product.type.value + " blanket"
    }

    return product.type.value
  }

  return product.title
}
